import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';

export type TLanguage = 'en' | 'ko';
interface Language {
  language: TLanguage;
  setLanguage: (language: TLanguage) => void;
}

export const useLanguageStore = create<Language>()(
  devtools(
    persist(
      (set) => ({
        language: 'ko',
        setLanguage: (language) =>
          set(() => ({
            language,
          })),
      }),
      { name: 'language', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);
