import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { ResLogin, ResourceInfo } from '@/apis/login/type';
import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';

type LoginUserInfo = Pick<ResLogin, 'resUserLoginRetVO'>['resUserLoginRetVO'];

type LoginInfoStoreState = {
  resources: ResourceInfo[] | undefined;
  userId: string | undefined;
};

type LoginInfoStoreAction = {
  setLoginInfo: (loginInfo: LoginUserInfo) => void;
  removeLoginInfo: () => void;
  fingerprint: string;
  setFingerprint: (fingerprint: string) => void;
  isMultiLogin: boolean;
  setIsMultiLogin: (isMulti: boolean) => void;
};

export const useLoginInfoStore = create<LoginInfoStoreState & LoginInfoStoreAction>()(
  devtools(
    persist(
      (set) => ({
        resources: undefined,
        userId: undefined,
        setLoginInfo: (loginInfo: LoginUserInfo) => {
          set(() => ({ resources: loginInfo.resourceNames, userId: loginInfo.id }));
        },
        removeLoginInfo: () => {
          set(() => ({ resources: undefined, userId: undefined }));
        },
        fingerprint: '',
        setFingerprint: (newFingerprint) => {
          set(() => ({ fingerprint: newFingerprint }));
        },
        isMultiLogin: true,
        setIsMultiLogin: (isMulti) => set(() => ({ isMultiLogin: isMulti })),
      }),
      { name: 'login-info', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useLoginInfoStore);
