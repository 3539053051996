import { useState, useEffect, createContext, useContext, PropsWithChildren, useMemo, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { differenceInSeconds } from 'date-fns';
import { connect, IClientOptions, MqttClient } from 'mqtt';
import { shallow } from 'zustand/shallow';

import type { ResPatientDevicePairList } from '@/apis/patient/type';
import { getDataId } from '@/pages/CentralMain/utils/dataKeyUtils';
import { parseUTC0String } from '@/utils/dateTimeUtils';

import useViewControlStore from '../viewControlStore/useViewControlStore';

export const mqttKeys = {
  pdpList: ['mqtt', 'pdpList'],
  all: ['mqtt', 'all'] as const,
  details: () => [...mqttKeys.all, 'detail'] as const,
  detail: (id: string) => [...mqttKeys.details(), id] as const,
};

type MqttConnectionStatus = 'connecting' | 'connected' | 'error' | 'disConnected';

interface MqttConnection {
  client: MqttClient;
  status: MqttConnectionStatus | undefined;
}
const MqttConnectionContext = createContext<MqttConnection | null>(null);

export const useMqttConnection = () => useContext(MqttConnectionContext);

const MqttConnectionProvider = ({ children }: PropsWithChildren) => {
  const brokerUrl = process.env.REACT_APP_MQTT_BROKER_URL;
  const targetTopic = process.env.REACT_APP_MQTT_TOPIC;

  const [client, setClient] = useState<MqttClient>();
  const [connectionStatus, setConnectionStatus] = useState<MqttConnectionStatus>();

  const { getAllDisplayedDataKey } = useViewControlStore(
    (state) => ({ getAllDisplayedDataKey: state.getAllDisplayedDataKey }),
    shallow,
  );

  useEffect(() => {
    if (!client && brokerUrl) {
      setConnectionStatus('connecting');
      const options: IClientOptions = {
        reconnectPeriod: 3 * 1000,
        clientId: `react-client-${Math.random().toString(16).substring(2, 8)}`,
        keepalive: 60 * 1000,
        connectTimeout: 10 * 1000,
        clean: true,
        path: '/mqtt',
      };
      const mqttConnectionClient = connect(brokerUrl, options);
      setClient(mqttConnectionClient);
    }
    // console.log(brokerUrl);
  }, [brokerUrl, client]);

  const queryClient = useQueryClient();
  const dataClearTimer = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const clearDataCache = () => {
      queryClient.resetQueries({ queryKey: mqttKeys.pdpList });
    };

    if (client) {
      client.on('reconnect', () => {
        setConnectionStatus('connecting');
      });
      client.on('connect', () => {
        if (targetTopic) {
          client.subscribe(targetTopic);
          setConnectionStatus('connected');
        }
      });
      client.on('error', (err) => {
        console.error('mqtt[connection error]: ', err);
        setConnectionStatus('error');
      });
      client.on('close', () => {
        setConnectionStatus('disConnected');
      });
      client.on('message', (topic, message) => {
        // console.debug('mqtt: ', topic, message.toString());
        if (topic !== targetTopic) {
          return;
        }
        if (process.env.REACT_APP_MODE === 'demo') {
          return;
        }

        if (dataClearTimer.current) {
          clearTimeout(dataClearTimer.current);
          dataClearTimer.current = null;
          dataClearTimer.current = setTimeout(clearDataCache, 5000); // 5sec동안 아무 데이터도 없으면 캐시를 비워줌
        } else {
          dataClearTimer.current = setTimeout(clearDataCache, 5000); // 5sec동안 아무 데이터도 없으면 캐시를 비워줌
        }
        try {
          const data: ResPatientDevicePairList = JSON.parse(message.toString());

          const newData = data.data?.at(0);
          if (!newData) {
            return;
          }
          const displayedDataKeys = getAllDisplayedDataKey();
          const deviceId = getDataId(newData);
          if (!displayedDataKeys.includes(deviceId)) {
            return;
          }
          const packetAcqTime = parseUTC0String(newData.acq_time);
          if (!packetAcqTime) {
            return;
          }
          const isPacketOutdated = differenceInSeconds(packetAcqTime, new Date()) > 5; // 5초보다 오래된 데이터는 수신하지 않는다
          if (isPacketOutdated) {
            return;
          }

          // update device data
          queryClient.setQueryData(mqttKeys.detail(deviceId), newData);
        } catch (error) {
          console.error('mqtt: data format error');
        }
      });
      client.on('end', () => {
        setClient(undefined);
        setConnectionStatus(undefined);
      });
    }
    // Clean up the MQTT connection when the component unmounts
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [client, getAllDisplayedDataKey, queryClient, targetTopic]);

  const value = useMemo(() => {
    // console.log(`[mqtt] status: ${connectionStatus}`);
    return client
      ? {
          client,
          status: connectionStatus,
        }
      : null;
  }, [client, connectionStatus]);

  return <MqttConnectionContext.Provider value={value}>{children}</MqttConnectionContext.Provider>;
};

export default MqttConnectionProvider;
