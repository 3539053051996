import { route, typeParser } from 'route-type-safe';

export const routes = {
  SAMPLE: route({ path: '/' }), // for testing
  AUDIO_TEST: route({ path: '/audio' }),
  HOME: route({ path: '/' }),
  CENTRAL_MAIN: route({ path: '/main', typeQuery: { viewRole: typeParser.string.required } }),
  PAGE_FALLBACK: route({ path: '/*' }),
  ERROR: route({ path: '/error' }),
  ERROR_INVALID_ACCESS: route({ path: '/error/invalid-access' }),
};
