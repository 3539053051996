import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useAudioStore } from '@/contexts/audioSettingStore/useAudioStore';
import { useLoginInfoStore } from '@/contexts/loginInfoStore/useLoginInfoStore';
import usePrimaryViewStore from '@/contexts/viewControlStore/usePrimaryViewStore';
import useSecondaryViewStore from '@/contexts/viewControlStore/useSecondaryViewStore';
import useViewControlStore from '@/contexts/viewControlStore/useViewControlStore';
import { routes } from '@/router/routes';
import { removeAuthToken } from '@/utils/localStorage/auth';

import useDualMonitor from './useDualMonitor';

const useLogout = () => {
  const navigate = useNavigate();
  const { removeLoginInfo } = useLoginInfoStore((state) => ({ removeLoginInfo: state.removeLoginInfo }), shallow);
  const { registerLastWindowSetting } = useViewControlStore(
    (state) => ({
      registerLastWindowSetting: state.registerLastWindowSetting,
    }),
    shallow,
  );

  const { closeDualMonitor } = useDualMonitor();

  const { setTempMute } = useAudioStore(
    (state) => ({
      setTempMute: state.setTempMute,
    }),
    shallow,
  );

  const { deregistPrimaryView } = usePrimaryViewStore(
    (state) => ({
      deregistPrimaryView: state.deregisterWindow,
    }),
    shallow,
  );
  const { secondaryId, deregistSecondaryView } = useSecondaryViewStore(
    (state) => ({
      secondaryId: state.activeViewId,

      deregistSecondaryView: state.deregisterWindow,
    }),
    shallow,
  );

  const logout = useCallback(() => {
    console.log('logout');
    navigate(routes.HOME.path, { replace: true });
    removeAuthToken();
    removeLoginInfo();
    registerLastWindowSetting({ main: 'open', viewOnly: secondaryId === null ? 'close' : 'open' });
    closeDualMonitor();
    // Amy : 로그아웃 할 때 temp mute false 로 바꿔주기
    setTempMute(false);
    deregistPrimaryView();
    deregistSecondaryView();
  }, [
    closeDualMonitor,
    deregistPrimaryView,
    deregistSecondaryView,
    navigate,
    registerLastWindowSetting,
    removeLoginInfo,
    secondaryId,
    setTempMute,
  ]);

  return { logout };
};

export default useLogout;
