import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { getRandomStr } from '@/utils/random';

interface ViewSessionStore {
  viewId: string;
}

const useViewSessionStore = create<ViewSessionStore>()(
  devtools(
    persist(
      () => ({
        viewId: `${Date.now()}-${getRandomStr()}`, // todo: upgrade to more unique auto generated value
      }),
      { name: 'view_session', storage: createJSONStorage(() => sessionStorage) },
    ),
  ),
);

export default useViewSessionStore;
