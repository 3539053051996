import loadable from '@loadable/component';
import { Navigate, RouteObject } from 'react-router-dom';

import { routes } from './routes';

const Central = loadable(() => import('@/pages/Central'));
const MainConainer = loadable(() => import('@/pages/CentralMain/MainConainer'));

const ErrorBase = loadable(() => import('@/pages/Error/ErrorBase'));
const Error404 = loadable(() => import('@/pages/Error/404'));
const InvalidAccessError = loadable(() => import('@/pages/Error/InvalidAccessError'));

// [page] HOME
export const HOME = {
  path: routes.HOME.path,
  redirect: routes.CENTRAL_MAIN.path,
  element: <Central />,
};

// [page] CENTRAL
export const CENTRAL_MAIN = {
  path: routes.CENTRAL_MAIN.path,
  redirect: routes.CENTRAL_MAIN.path,
  children: [{ path: routes.CENTRAL_MAIN.path, element: <MainConainer /> }],
};

// [page] ERROR
export const ERROR = {
  path: routes.ERROR.path,
  element: <ErrorBase />,
  children: [
    { path: routes.ERROR_INVALID_ACCESS.path, element: <InvalidAccessError /> },
    { path: routes.ERROR.path, element: <Error404 /> },
  ],
};

// [page] FALLBACK (router 404)
export const PAGE_FALLBACK = {
  path: routes.PAGE_FALLBACK.path,
  element: <Navigate replace to={routes.ERROR.build({})} />,
};

const routeInfo: RouteObject[] = [HOME, CENTRAL_MAIN, ERROR, PAGE_FALLBACK];
export default routeInfo;
