import { parseISO } from 'date-fns';

export const getTimeDifferenceFromNow = (baseTime: Date) => {
  const now = new Date();
  const baseDate = new Date(baseTime);
  return baseDate.getTime() - now.getTime();
};

const UTC0_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export const parseUTC0String = (utc0String: string) => {
  if (!utc0String.match(UTC0_REGEX)) {
    return null;
  }
  const date = parseISO(utc0String);
  return date;
};
