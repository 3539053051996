import React, { createContext, useState, useEffect, useCallback, PropsWithChildren } from 'react';

import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { confirm } from '@/components/Tools/ConfirmAlert';
import useLanguage from '@/hooks/useLanguage';
import useStorageEvent from '@/hooks/useStorageEvent';
import useCurrentViewInfo from '@/pages/CentralMain/hooks/useCurrentViewInfo';
import { routes } from '@/router/routes';
import checkBrowser from '@/utils/checkBrowser';
import { removeAuthToken } from '@/utils/localStorage/auth';

import { useLoginInfoStore } from '../loginInfoStore/useLoginInfoStore';
import useResetStore from '../resetStore/useResetStore';
import usePrimaryViewStore from '../viewControlStore/usePrimaryViewStore';
import useSecondaryViewStore from '../viewControlStore/useSecondaryViewStore';

type WindowState = {
  windowInstance: Window | null;
  setWindowInstance: React.Dispatch<React.SetStateAction<Window | null>>;
  closeWindow: () => void;
};

export const WindowContext = createContext<WindowState | undefined>(undefined);

export const WindowProvider = ({ children }: PropsWithChildren) => {
  const [windowInstance, setWindowInstance] = useState<Window | null>(null);

  const { translate } = useLanguage();

  useEffect(() => {
    if (checkBrowser() !== 'CHROME') {
      confirm({
        title: translate('browserCheck'),
        message: translate('recommendChrome'),
        buttons: [
          {
            label: translate('confirm'),
          },
        ],
      });
    }
  }, [translate]);

  // 창 닫기 함수
  const closeWindow = useCallback(() => {
    if (windowInstance && !windowInstance.closed) {
      windowInstance.close();
      setWindowInstance(null);
    }
  }, [windowInstance]);

  // 컴포넌트 언마운트 시 창 닫기
  useEffect(() => {
    return () => {
      closeWindow();
    };
  }, [closeWindow]);

  const { viewRole, viewId } = useCurrentViewInfo();

  const navigate = useNavigate();

  const { deregistPrimaryView } = usePrimaryViewStore(
    (state) => ({
      deregistPrimaryView: state.deregisterWindow,
    }),
    shallow,
  );

  const { removeLoginInfo } = useLoginInfoStore((state) => ({ removeLoginInfo: state.removeLoginInfo }), shallow);

  const { deregistSecondaryView } = useSecondaryViewStore(
    (state) => ({
      secondaryId: state.activeViewId,
      deregistSecondaryView: state.deregisterWindow,
    }),
    shallow,
  );

  const { setIsReset } = useResetStore(
    (state) => ({
      setIsReset: state.setIsRest,
      isReset: state.isReset,
    }),
    shallow,
  );

  // Amy: primary view 변경 시 (보통 재설정 버튼 클릭 시) 정리하는 부분
  // Amy: hook 안에 있었지만 여러번 발생되는 문제로 provider로 이동
  const handleViewControlId = useCallback(
    (event: StorageEvent) => {
      if (event.key !== 'primary-view' || !event.newValue || event.newValue === event.oldValue) {
        return undefined;
      }
      if (viewRole !== 'main') {
        // 화면 조작은 main만 할 수 있음
        return undefined;
      }
      try {
        const storageValue = JSON.parse(event.newValue);
        if (storageValue?.state?.activeViewId !== viewId) {
          // 로그인 정보 삭제
          removeAuthToken();
          removeLoginInfo();
          // 로그인 창으로 이동
          navigate(routes.HOME.path, { replace: true });
          // 화면 정리
          deregistPrimaryView();
          deregistSecondaryView();

          closeWindow();
          // Amy: reset이 끝났음으로 업데이트
          setIsReset(false);
        }
      } catch {
        console.error('cannot parse storage value');
      }
      return undefined;
    },
    [closeWindow, deregistPrimaryView, deregistSecondaryView, navigate, removeLoginInfo, setIsReset, viewId, viewRole],
  );
  useStorageEvent({ storageEventCallback: handleViewControlId });

  return (
    <WindowContext.Provider value={{ windowInstance, setWindowInstance, closeWindow }}>
      {children}
    </WindowContext.Provider>
  );
};
