import { useCallback } from 'react';

import { shallow } from 'zustand/shallow';

import languages from '@/constants/languages';
import { useLanguageStore } from '@/contexts/languageStore/useLanguageStore';
import { useSettingStore } from '@/contexts/settingStore/useSettingStore';
import useViewControlStore from '@/contexts/viewControlStore/useViewControlStore';

export default function useLanguage() {
  const { language, setLanguage } = useLanguageStore(
    (state) => ({
      language: state.language,
      setLanguage: state.setLanguage,
    }),
    shallow,
  );

  const translate = useCallback(
    (key: keyof typeof languages) => {
      return languages[key][language] || '';
    },
    [language],
  );

  const setIsViewDataSorted = useViewControlStore((state) => state.setIsViewDataSorted, shallow);
  const sortOrder = useSettingStore((state) => state.sortOrder, shallow);

  const changeLanguage = useCallback(
    (lang: string) => {
      if (lang === 'ko' || lang === 'en') setLanguage(lang);
      if (sortOrder === 'patient-name-asc' || sortOrder === 'patient-name-desc') {
        setIsViewDataSorted(false);
      }
    },
    [setIsViewDataSorted, setLanguage, sortOrder],
  );

  const translateSex = useCallback(
    (sex: string | undefined) => {
      if (!sex) {
        return '';
      }
      if (language === 'ko') {
        if (sex === 'F') {
          return '여';
        }
        if (sex === 'M') {
          return '남';
        }
        return '기타';
      }
      return sex;
    },
    [language],
  );

  return { translate, changeLanguage, language, translateSex };
}
