import { useCallback, useMemo, useContext, useEffect } from 'react';

import { shallow } from 'zustand/shallow';

import { WindowContext } from '@/contexts/dualMonitorProvider';
import usePrimaryViewStore from '@/contexts/viewControlStore/usePrimaryViewStore';
import useSecondaryViewStore from '@/contexts/viewControlStore/useSecondaryViewStore';
import useViewControlStore from '@/contexts/viewControlStore/useViewControlStore';
import { routes } from '@/router/routes';

import useCurrentViewInfo from './useCurrentViewInfo';
import useUserScreenDetails from './useUserScreenDetails';
import { ViewRole, VIEW_ROLES } from '../types';

const useDualMonitor = () => {
  const context = useContext(WindowContext);

  if (!context) {
    throw new Error('useWindowOpener must be used within a WindowProvider');
  }

  const { windowInstance, setWindowInstance, closeWindow } = context;

  const { viewData, registerLastWindowSetting } = useViewControlStore(
    (state) => ({
      viewData: state.viewData,
      lastWindowSetting: state.lastWindowSetting,
      registerLastWindowSetting: state.registerLastWindowSetting,
    }),
    shallow,
  );
  const { primaryId, deregistPrimaryView } = usePrimaryViewStore(
    (state) => ({
      primaryId: state.activeViewId,
      deregistPrimaryView: state.deregisterWindow,
    }),
    shallow,
  );
  const { secondaryId, deregistSecondaryView } = useSecondaryViewStore(
    (state) => ({
      secondaryId: state.activeViewId,
      deregistSecondaryView: state.deregisterWindow,
    }),
    shallow,
  );

  const { viewRole } = useCurrentViewInfo();
  const { getLatestSavedWindowPosition } = useUserScreenDetails();

  // monitor status
  const isDualMonitor = useMemo(() => {
    return !(primaryId == null || secondaryId == null);
  }, [primaryId, secondaryId]);

  // close viewOnly window
  const closeDualMonitor = useCallback(() => {
    closeWindow();
  }, [closeWindow]);

  // close window => deregister window
  const deleteView = useCallback(
    (viewRole: ViewRole | null, secondaryId: string | null) => {
      if (!viewRole) return;
      if (viewRole === 'main') {
        if (isDualMonitor) {
          closeWindow();
        }
        deregistPrimaryView();
        registerLastWindowSetting({ main: 'open', viewOnly: secondaryId === null ? 'close' : 'open' });
      } else {
        deregistSecondaryView();
      }
    },
    [isDualMonitor, closeWindow, deregistPrimaryView, registerLastWindowSetting, deregistSecondaryView],
  );

  /* This `useEffect` hook is setting up event listeners for the `beforeunload` event on the `window`.
When the user navigates away from the current page or closes the browser tab/window, the
`beforeunload` event is triggered. */
  useEffect(() => {
    const handleDeleteView = () => deleteView(viewRole, secondaryId);
    window.addEventListener('beforeunload', handleDeleteView); // Amy: main 일 때
    return () => {
      window.removeEventListener('beforeunload', handleDeleteView);
    };
  }, [deleteView, secondaryId, viewRole]);

  // open viewOnly window
  const openViewOnlyMonitor = useCallback(async () => {
    if (windowInstance && !windowInstance.closed) {
      return undefined;
    }

    const viewOnlyPageRouteInfo = routes.CENTRAL_MAIN.build({ query: { viewRole: VIEW_ROLES.viewOnly } });
    const popupPosition = await getLatestSavedWindowPosition('viewOnly');
    // Amy : 작아지는 부분 수정 (1씩 줄어들게 open 되어서 1 증가해서 열기)
    const updatedStr = popupPosition.replace(/height=(\d+)/, function plus(match, p1) {
      return `height=${parseInt(p1, 10) + 1}`;
    });

    const newWindow = window.open(
      viewOnlyPageRouteInfo.pathname + viewOnlyPageRouteInfo.search,
      // * note: new window
      '_blank',
      // * note: for popup
      updatedStr,
    );
    setWindowInstance(newWindow);

    return undefined;
  }, [getLatestSavedWindowPosition, setWindowInstance, windowInstance]);

  const toggleDualMonitor = useCallback(async () => {
    if (isDualMonitor && windowInstance && !windowInstance.closed) {
      closeDualMonitor();
    } else {
      openViewOnlyMonitor();
    }
  }, [closeDualMonitor, isDualMonitor, openViewOnlyMonitor, windowInstance]);

  const isViewOnlyWindowClose = useMemo(() => secondaryId === null, [secondaryId]);
  const viewOnlyDataCount = useMemo(() => viewData.viewOnly.length, [viewData.viewOnly.length]);

  return { toggleDualMonitor, isViewOnlyWindowClose, viewOnlyDataCount, openViewOnlyMonitor, closeDualMonitor };
};

export default useDualMonitor;
