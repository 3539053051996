// React logic for managing LightningChart JS instances that are shared
// between any LCJS based components that can be visible at the same time
// In simple use cases with 1-2 charts visible at once, there is no need to use these.
// However, with more charts visible at once, this gives an incredible performance advantage, since all charts use a shared LC context.
// See usage example in ./Components/MyChartComponent.js and ./App.js

import React, { createContext, useEffect, useRef, useState, useId, PropsWithChildren, useMemo } from 'react';

import { lightningChart } from '@arction/lcjs';
import type { LightningChart } from '@arction/lcjs';

export const lcjsLicense = {
  license:
    '0002-vdyaly5bBKgX4i4cLtU+2pE0NiI6swDdmpE7Qwat0SCUAghgnG8BqNiLSa0ZSarFBmZ/7VSlRnWfs4DsoX8yTyvnDsBdsp2jq8E9QoYSYwiweDSwo02aCgODhCLjkE2P3mzt63qkACC/QjpXxYeKUHaeB3yRgIfzWlNjdKdKe7vG/BLKsSUEMQ7h7bcT6+Y2g9ymORz7GcFx/gmn9jFOvABuWHI5crSpo+mgEdH5kiuNWVZdivu8nGjDZrVtJi+lyckPI7Sptlv0SA==-MEUCIQCd5ZILM4ZFRrFW+JKpLdxp1AITGlykpbk/G/CO9+2ksAIgOCkIHe6lrKvZMHSroxP+VeG9IjvBX5sR2A7LQWMYjHA=',
};

type LcContextState = { cell: LightningChart | null; modal: LightningChart | null };
export const LCContext = createContext<LcContextState | null>(null);

export function LCHost({ children }: PropsWithChildren) {
  const [canvasState, setCanvasState] = useState<string | HTMLCanvasElement | null>(null);
  const lcRef = useRef<LightningChart | null>(null);
  const id = useId();
  const [lcState, setLcState] = useState<LightningChart | null>(null);
  // for modal
  const [modalCanvasState, setModalCanvasState] = useState<string | HTMLCanvasElement | null>(null);
  const lcModalRef = useRef<LightningChart | null>(null);
  const modalId = useId();
  const [lcModalState, setLcModalState] = useState<LightningChart | null>(null);

  useEffect(() => {
    if (!lcRef.current && canvasState) {
      try {
        lcRef.current = lightningChart({
          license: lcjsLicense.license,
          sharedContextOptions: {
            canvas: canvasState,
            useIndividualCanvas: false,
          },
        });
        setLcState(lcRef.current);
      } catch (e) {
        console.error(e);
      }
    }
    if (!lcModalRef.current && modalCanvasState) {
      try {
        lcModalRef.current = lightningChart({
          ...lcjsLicense,
          sharedContextOptions: {
            canvas: modalCanvasState,
            useIndividualCanvas: false,
          },
        });
        setLcModalState(lcModalRef.current);
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      if (lcRef.current && 'dispose' in lcRef.current) {
        lcRef.current.dispose();
        lcRef.current = null;
        setLcState(null);
      }
      if (lcModalRef.current && 'dispose' in lcModalRef.current) {
        lcModalRef.current.dispose();
        lcModalRef.current = null;
        setLcModalState(null);
      }
    };
  }, [canvasState, modalCanvasState]);

  const contextValue: LcContextState = useMemo(() => ({ cell: lcState, modal: lcModalState }), [lcModalState, lcState]);

  return (
    <>
      <canvas
        key={id}
        ref={(newRef: string | HTMLCanvasElement | null) => setCanvasState(newRef)}
        style={{ position: 'relative', zIndex: 2 }} // z-index 설정 * (주의)셀이 drag되는 도중의 높이보다 낮아야 화면을 가리지 않음
      />
      <canvas
        key={modalId}
        ref={(newRef: string | HTMLCanvasElement | null) => setModalCanvasState(newRef)}
        style={{ position: 'relative', zIndex: 11 }} // z-index 설정 * (주의) modal보다 위의 zIndex를 가져야 함
      />
      <LCContext.Provider value={contextValue}>{children}</LCContext.Provider>
    </>
  );
}
