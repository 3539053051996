import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { DEFAULT_COLOR_SETTING, DEFAULT_PARAM_SETTING } from '@/constants/data';
import { TParamSetting } from '@/constants/types';
import { VERSION } from '@/constants/version';
import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';
import { CELL_SORTING_OPTION, CellSortingOption } from '@/pages/CentralMain/organisms/SortButton/type';

import { TConnectionInfo } from '../patientInfoStore/usePatientInfoStore';

interface Setting {
  maxDisplayNum: number;
  paramSetting: TParamSetting;
  colorSetting: { [key: string]: string };
  monoColorMode: string | null;
  setParamSetting: (setting: TParamSetting) => void;
  setMaxDisplayNum: (num: number) => void;
  setColorSetting: (colorSetting: { [key: string]: string }) => void;
  setMonoColorMode: (color: string | null) => void;
  title: TTitle;
  setTitle: (key: keyof TTitle, value: keyof TSelectableTitle) => void;
  version: number;
  setVersion: (version: number) => void;
  resetParamSetting: () => void;
  isDeviceAlias: boolean;
  setIsDeviceAlias: (isAlias: boolean) => void;
  resetSettings: () => void;
  colorTheme: string;
  setColorTheme: (colorTheme: string) => void;
  sortOrder: CellSortingOption;
  setSortOrder: (sortOrder: CellSortingOption) => void;
  isGroupingEnabled: boolean;
  setIsGroupingEnabled: (value: boolean) => void;
}

export type TSelectableTitle = {
  deviceSerial: string;
  model: string;
  name: string;
  therapyMode: string;
  modelSerial: string;
  age: string;
} & Partial<TConnectionInfo[0]>;

export type TTitle = { first: keyof TSelectableTitle; second: keyof TSelectableTitle; third: keyof TSelectableTitle };

export const useSettingStore = create<Setting>()(
  devtools(
    persist(
      (set, get) => ({
        maxDisplayNum: 16,
        setMaxDisplayNum: (num) => set(() => ({ maxDisplayNum: num })),
        paramSetting: DEFAULT_PARAM_SETTING,
        setParamSetting: (setting) => {
          set(() => ({ paramSetting: { ...setting } }));
        },
        colorSetting: DEFAULT_COLOR_SETTING,
        setColorSetting: (colorSetting) => {
          set(() => ({ colorSetting: { ...colorSetting } }));
        },
        monoColorMode: null,
        setMonoColorMode: (color) => {
          set(() => ({ monoColorMode: color }));
        },
        title: { first: 'model', second: 'patId', third: 'bedId' },
        setTitle: (key, value) => {
          const { title } = get();
          const newTitle = { ...title, [key]: value };
          set(() => ({ title: newTitle }));
        },
        version: VERSION,
        setVersion: (version: number) => {
          set(() => ({ version }));
        },
        resetParamSetting: () => {
          set(() => ({ paramSetting: DEFAULT_PARAM_SETTING }));
        },
        isDeviceAlias: false,
        setIsDeviceAlias: (isAlias) => {
          set(() => ({ isDeviceAlias: isAlias }));
        },
        resetSettings: () => {
          set(() => ({ paramSetting: DEFAULT_PARAM_SETTING, colorSetting: DEFAULT_COLOR_SETTING }));
        },
        colorTheme: '#0a66fa',
        setColorTheme: (color) => {
          set(() => ({ colorTheme: color }));
        },
        sortOrder: CELL_SORTING_OPTION['patient-name-asc'],
        setSortOrder: (sortOrder: CellSortingOption) => {
          set((state) => ({ ...state, sortOrder }));
        },
        isGroupingEnabled: true,
        setIsGroupingEnabled: (value: boolean) => {
          set((prev) => ({ ...prev, isGroupingEnabled: value }));
        },
      }),

      { name: 'setting', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useSettingStore);
