import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '../utils/withStorageDOMEvents';

type State = {
  activeViewId: string | null;
  isFullScreen: boolean;
  isAutoReloadConfirmed: boolean;
};

interface Actions {
  registerWindow: (viewId: string) => boolean;
  deregisterWindow: () => boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  setIsAutoReloadConfirmed: (isAutoReloadConfirmed: boolean) => void;
}

const initialState: State = {
  activeViewId: null,
  isFullScreen: false,
  isAutoReloadConfirmed: true,
};

const useSecondaryViewStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        registerWindow: (viewId) => {
          const { activeViewId } = get();
          if (activeViewId !== null && activeViewId !== viewId) {
            return false;
          }

          set((state) => ({ ...state, activeViewId: viewId }));
          return true;
        },
        deregisterWindow: () => {
          set((state) => ({
            ...state,
            activeViewId: null,
          }));
          return true;
        },
        setIsFullScreen: (isFullScreen) => {
          set((state) => ({
            ...state,
            isFullScreen,
          }));
        },
        setIsAutoReloadConfirmed: (isAutoReloadConfirmed: boolean) => {
          set((state) => ({ ...state, isAutoReloadConfirmed }));
        },
      }),
      { name: 'secondary-view', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useSecondaryViewStore);
export default useSecondaryViewStore;
